import type { VariantProps } from "class-variance-authority";
import type { LinkProps } from "next/link";
import type { ReactNode } from "react";
import type { JSX } from "react";
import { createElement, forwardRef } from "react";
import Link from "next/link";
import { cva } from "class-variance-authority";

import { cn } from "@gility/lib";
import { applyStyleToMultipleVariants } from "@gility/lib/cva";
import { type SVGComponent } from "@gility/lib/types/SVGComponent";

type InferredVariantProps = VariantProps<typeof buttonClasses>;

export type ButtonColor = NonNullable<InferredVariantProps["color"]>;
export type ButtonBaseProps = {
  /** Action that happens when the button is clicked */
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /**Left aligned icon*/
  icon?: SVGComponent | ReactNode;
  /**Right aligned icon */
  // EndIcon?: SVGComponent;
  shallow?: boolean;
} & Omit<InferredVariantProps, "color"> & {
    color?: ButtonColor;
  };

export type ButtonProps = ButtonBaseProps &
  (
    | (Omit<JSX.IntrinsicElements["a"], "href" | "onClick" | "ref"> & LinkProps)
    | (Omit<JSX.IntrinsicElements["button"], "onClick" | "ref"> & { href?: never })
  );

const primaryVariants = [
  {
    disabled: true,
    color: "primary",
    className: "bg-gray-800 bg-opacity-30",
  },
  {
    loading: true,
    color: "primary",
    className: "bg-gray-800/30 text-white/70 ",
  },
  ...applyStyleToMultipleVariants({
    disabled: [undefined, false],
    color: "primary",
    className:
      "bg-pink focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-pink-500 ",
  }),
  ...applyStyleToMultipleVariants({
    variant: ["link", "linkStrong", "linkNoUnderline"],
    color: "primary",
    disabled: [undefined, false],
    className: "text-pink",
  }),
];

const blackVariants = [
  {
    color: "black",
    disabled: true,
    className: "bg-black bg-opacity-30",
  },
  {
    color: "black",
    loading: true,
    className: "bg-black/30 text-white/70",
  },
  ...applyStyleToMultipleVariants({
    color: "black",
    disabled: [undefined, false],
    className:
      "bg-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-black-500 ",
  }),
  ...applyStyleToMultipleVariants({
    color: "black",
    variant: ["link", "linkStrong"],
    className: "text-black",
  }),
];

const grayVariants = [
  {
    color: "gray",
    className: "border border-[#B7C5D0]",
  },
  ...applyStyleToMultipleVariants({
    color: "gray",
    variant: ["link", "linkStrong", "linkNoUnderline"],
    className: "text-customGray-600 border-0",
  }),
  ...applyStyleToMultipleVariants({
    color: "lightGray",
    variant: ["link", "linkStrong", "linkNoUnderline"],
    className: "text-customGray-500 border-0",
  }),
];

// TODO: merge into linkVariants below?
const newLinkVariants = [
  {
    variant: "link",
    className: "p-0 font-normal bg-none! hover:bg-none! underline textshade-none",
  },
  {
    variant: "linkStrong",
    className: "p-0 font-medium bg-none! hover:bg-none! underline textshade-none",
  },
  {
    variant: "linkNoUnderline",
    className: "p-0 font-normal bg-none! hover:bg-none! no-underline! textshade-none",
  },
  {
    disabled: true,
    variant: "link",
    className: "text-gray-900/30",
  },
  {
    disabled: true,
    variant: "linkStrong",
    className: "text-gray-900/30",
  },
  {
    variant: "link",
    color: "primary",
    className: "text-pink",
  },
];

const secondaryVariants = [
  {
    disabled: true,
    color: "secondary",
    className: "border border-gray-200 bg-opacity-30 text-gray-900/30",
  },
  {
    loading: true,
    color: "secondary",
    className: "bg-gray-100 text-gray-900/30 ",
  },
  ...applyStyleToMultipleVariants({
    disabled: [undefined, false],
    color: "secondary",
    className:
      "text-pink-900 dark:border-darkgray-300 bg-pink-100 hover:border-gray-400 focus-visible:bg-gray-100  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-gray-900 ",
  }),
  ...applyStyleToMultipleVariants({
    variant: ["link", "linkStrong", "linkNoUnderline"],
    color: "secondary",
    disabled: [undefined, false],
    className: "text-pink-100",
  }),
];

const transparentVariants = [
  {
    disabled: true,
    color: "transparent",
    className: "border:gray-200 bg-opacity-30 text-gray-900/30 ",
  },
  {
    loading: true,
    color: "transparent",
    className: "bg-gray-100 text-gray-900/30 ",
  },
  ...applyStyleToMultipleVariants({
    disabled: [undefined, false],
    color: "transparent",
    className:
      "border border-white hover:text-gray-900 hover:bg-gray-100 focus-visible:bg-gray-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-gray-900 ",
  }),
];

const linkVariants = [
  {
    disabled: true,
    color: "link",
    className: "text-gray-900/30",
  },
  {
    loading: true,
    color: "link",
    className: "text-pink ",
  },
  ...applyStyleToMultipleVariants({
    disabled: [undefined, false],
    color: "link",
    className: "text-pink font-semibold",
  }),
];

const outlineVariants = [
  {
    disabled: true,
    color: "outline",
    className: "border border-gray-200 bg-opacity-30 text-gray-900/30 ",
  },
  {
    loading: true,
    color: "outline",
    className: "bg-gray-100 text-gray-900/30 ",
  },
  ...applyStyleToMultipleVariants({
    disabled: [undefined, false],
    color: "outline",
    className:
      "text-blue-900 border border-blue hover:text-blue-700  hover:border-blue-700 focus-visible:bg-gray-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-gray-900",
  }),
];

const redVariants = [
  {
    disabled: true,
    color: "red",
    className:
      "text-red-700/30 dark:text-red-700/30 bg-red-100/40 dark:bg-red-100/80 border border-red-200",
  },
  {
    loading: true,
    color: "red",
    className:
      "text-red-700/30 dark:text-red-700/30 hover:text-red-700/30 bg-red-100 border border-red-200",
  },
  ...applyStyleToMultipleVariants({
    disabled: [false, undefined],
    color: "red",
    className:
      "border text-gray-900 hover:text-red-700 focus-visible:text-red-700 hover:border-red-100 focus-visible:border-red-100 hover:bg-red-100 focus-visible:bg-red-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-red-700",
  }),
  ...applyStyleToMultipleVariants({
    variant: ["link", "linkStrong", "linkNoUnderline"],
    color: "red",
    disabled: [undefined, false],
    className: "text-red-700",
  }),
];

const greenVariants = [
  {
    disabled: true,
    color: "green",
    className:
      "text-green-700/30 dark:text-green-700/30 bg-green-100/40 dark:bg-green-100/80 border border-green-200",
  },
  {
    loading: true,
    color: "green",
    className:
      "text-green-700/30 dark:text-green-700/30 hover:text-green-700/30 bg-green-100 border border-green-200",
  },
  ...applyStyleToMultipleVariants({
    disabled: [false, undefined],
    color: "green",
    className:
      "text-white bg-green focus-visible:text-black hover:border-green focus-visible:border-green focus-visible:bg-green focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-green textshade-black",
  }),
  ...applyStyleToMultipleVariants({
    variant: ["link", "linkStrong", "linkNoUnderline"],
    color: "green",
    disabled: [undefined, false],
    className: "text-green",
  }),
];

const whiteVariants = [
  {
    disabled: true,
    color: "white",
    className:
      "text-blue-700/30 dark:text-blue-700/30 bg-gray-100/40 dark:bg-gray-100/80 border border-gray-200",
  },
  {
    loading: true,
    color: "white",
    className:
      "text-blue-700/30 dark:text-blue-700/30 hover:text-blue-700/30 bg-gray-100 border border-gray-200",
  },
  ...applyStyleToMultipleVariants({
    disabled: [false, undefined],
    color: "white",
    className:
      "text-blue bg-white focus-visible:text-black hover:border-white focus-visible:border-white focus-visible:bg-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset focus-visible:ring-white",
  }),
  ...applyStyleToMultipleVariants({
    variant: ["link", "linkStrong", "linkNoUnderline"],
    color: "white",
    disabled: [undefined, false],
    className: "text-blue",
  }),
];

const iconVariants = [
  // https://github.com/joe-bell/cva/issues/95 created an issue about using !p-2 on the icon variants as i would expect this to take priority
  {
    variant: "icon",
    size: "base",
    className: "min-h-[36px] min-w-[36px] !p-2",
  },
  {
    variant: "icon",
    size: "sm",
    className: "h-6 w-6 !p-1",
  },
  {
    variant: "fab",
    size: "base",
    className: "h-14 md:h-9 md:w-auto md:px-4 md:py-2.5",
  },
];

export const buttonClasses = cva("relative inline-flex items-center rounded-md font-normal", {
  variants: {
    variant: {
      button: "",
      link: "",
      linkStrong: "",
      linkNoUnderline: "",
      icon: "flex justify-center",
      fab: "radix-state-open:rotate-45 md:radix-state-open:rotate-0 radix-state-open:shadown-none radix-state-open:ring-0 justify-center rounded-full !shadow-none",
    },
    color: {
      primary: "text-white",
      secondary: "text-white",
      transparent: "text-white",
      red: "",
      green: "text-white",
      outline: "",
      ghost: "hover:bg-ice hover:text-black",
      link: "",
      black: "text-black",
      gray: "#212529",
      lightGray: "text-customGray-500",
      white: "text-blue",
    },
    size: {
      sm: "px-3 py-2 text-sm leading-4" /** For backwards compatibility */,
      base: "h-9 px-4 py-2.5",
      lg: "h-11 px-4 py-2.5",
    },
    loading: {
      true: "cursor-wait",
    },
    disabled: {
      true: "cursor-not-allowed",
    },
  },
  compoundVariants: [
    ...primaryVariants,
    ...secondaryVariants,
    ...transparentVariants,
    ...redVariants,
    ...iconVariants,
    ...greenVariants,
    ...whiteVariants,
    ...outlineVariants,
    ...linkVariants,
    ...blackVariants,
    ...grayVariants,
    ...newLinkVariants,
  ],
  defaultVariants: {
    variant: "button",
    color: "primary",
    size: "base",
  },
});

const LoadingIndicator = () => (
  <div
    className="mr-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
    role="status"></div>
);

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  function Button(props: ButtonProps, forwardedRef) {
    const {
      loading = false,
      color = "primary",
      size,
      variant = "button",
      type = "button",
      icon,
      shallow,
      children,
      href,
      // attributes propagated from `HTMLAnchorProps` or `HTMLButtonProps`
      ...passThroughProps
    } = props;
    // Buttons are **always** disabled if we're in a `loading` state
    const disabled = props.disabled == true || loading;

    const isLink = typeof href !== "undefined" && !disabled;
    const elementType = isLink ? "a" : "button";

    const innerChildren = (
      <>
        {loading ? <LoadingIndicator /> : icon}
        {children}
      </>
    );

    const element = createElement(elementType, {
      ...passThroughProps,
      children: innerChildren,
      disabled,
      type: !isLink ? type : undefined,
      href: isLink ? href : undefined,
      ref: forwardedRef,
      className: cn(
        buttonClasses({ color, size, loading, disabled: props.disabled, variant }),
        props.className,
      ),
      // if we click a disabled button, we prevent going through the click handler
      onClick: disabled
        ? (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
          }
        : props.onClick,
    });

    return href && !disabled ? (
      <Link passHref href={href} shallow={shallow && shallow} legacyBehavior>
        {element}
      </Link>
    ) : (
      element
    );
  },
);
