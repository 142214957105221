import { create } from "zustand";

type FeatureFlagsData = {
  doceboFlowEnabled: boolean;
  moxiaEnabled: boolean;
};

interface FeatureFlagsStore {
  flags: FeatureFlagsData;
  setFlags: (flags: FeatureFlagsData) => void;
  ready: boolean;
  setReady: (ready: boolean) => void;
}

const featureFlagsStore = create<FeatureFlagsStore>((set) => ({
  flags: {
    doceboFlowEnabled: false,
    moxiaEnabled: false,
  },
  setFlags: (flags) => set({ flags }),
  ready: false,
  setReady: (ready) => set({ ready }),
}));

export const useFeatureFlags = () => {
  return featureFlagsStore((state) => state);
};
