import { type ReactElement } from "react";
import Image from "next/image";
import { cn } from "@gility/lib";

interface EmptySearchProps {
  mainText?: string | ReactElement;
  subText?: string | ReactElement | false;
  className?: string;
}

export const EmptySearch = ({ mainText, subText, className }: EmptySearchProps) => (
  <div className={cn("flex flex-col items-center justify-center py-12", className)}>
    <Image src="/assets/catalog/empty-search.svg" width={194} height={165} alt="Nessun risultato" />
    <div className="mt-6 font-medium">{mainText}</div>
    <div>{subText}</div>
  </div>
);
