import type { ReactNode } from "react";
import type { JSX } from "react";
import React, { useState } from "react";
import { useRouter } from "next/router";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import type { SVGComponent } from "@gility/lib/types/SVGComponent";
import { cn } from "@gility/lib";

import type { ButtonProps } from "../../components/button";
import { Button } from "../../components/button";
import { Heading } from "../heading";
import { X } from "../icon";

export type DialogProps = React.ComponentProps<(typeof DialogPrimitive)["Root"]> & {
  name?: string;
  clearQueryParamsOnClose?: string[];
  fullHeight?: boolean;
};
export function Dialog(props: DialogProps) {
  const router = useRouter();
  const { children, name, ...dialogProps } = props;
  // only used if name is set
  const [open, setOpen] = useState(!!dialogProps.open);

  if (name) {
    const clearQueryParamsOnClose = ["dialog", ...(props.clearQueryParamsOnClose ?? [])];
    dialogProps.onOpenChange = (open) => {
      if (props.onOpenChange) {
        props.onOpenChange(open);
      }
      // toggles "dialog" query param
      if (open) {
        router.query.dialog = name;
      } else {
        const query = router.query;
        clearQueryParamsOnClose.forEach((queryParam) => {
          delete query[queryParam];
        });
        void router.push(
          {
            pathname: router.pathname,
            query,
          },
          undefined,
          { shallow: true },
        );
      }
      setOpen(open);
    };
    // handles initial state
    if (!open && router.query.dialog === name) {
      setOpen(true);
    }
    // allow overriding
    if (!("open" in dialogProps)) {
      dialogProps.open = open;
    }
  }

  return <DialogPrimitive.Root {...dialogProps}>{children}</DialogPrimitive.Root>;
}

const DialogPortal = ({ children, ...props }: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal {...props}>
    <div className="fixed inset-0 z-50 flex items-start justify-center sm:items-center">
      {children}
    </div>
  </DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

type DialogContentProps = React.ComponentProps<(typeof DialogPrimitive)["Content"]> & {
  size?: "full" | "xl" | "lg" | "md";
  type?: "creation" | "confirmation";
  title?: string;
  titleElement?: ReactNode;
  fullHeight?: boolean;
  description?: string | JSX.Element | undefined;
  closeText?: string;
  actionDisabled?: boolean;
  Icon?: SVGComponent;
  enableOverflow?: boolean;
};

// enableOverflow:- use this prop whenever content inside DialogContent could overflow and require scrollbar
export const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  (
    {
      children,
      title,
      titleElement,
      description,
      Icon,
      enableOverflow,
      fullHeight = false,
      type = "creation",
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <DialogPortal>
        <DialogPrimitive.Overlay
          className={cn(
            "fixed inset-0 z-40 bg-gray-500 bg-opacity-80",
            "backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
          )}
        />
        <DialogPrimitive.Content
          {...props}
          className={cn(
            "fixed left-[50%] top-[50%] z-40 flex min-w-[360px] translate-x-[-50%] translate-y-[-50%] flex-col rounded-lg bg-white text-left shadow-xl focus-visible:outline-none sm:w-full sm:align-middle",
            "shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
            props.size == "full"
              ? "h-full !max-h-full w-full rounded-none p-8"
              : props.size == "xl"
                ? "p-8 sm:max-w-[90rem]"
                : props.size == "lg"
                  ? "p-8 sm:max-w-[70rem]"
                  : props.size == "md"
                    ? "p-8 sm:max-w-[48rem]"
                    : "p-8 sm:max-w-[35rem]",
            "max-h-[90vh]",
            fullHeight && "h-[90vh]",
            enableOverflow ? "overflow-auto" : "overflow-hidden",
            `${props.className ?? ""}`,
          )}
          ref={forwardedRef}>
          {type === "creation" && (
            <div className="relative flex h-full min-h-0 flex-col">
              {titleElement
                ? titleElement
                : title && <DialogHeader title={title} subtitle={description} />}

              <div className="flex min-h-0 flex-1 flex-col">{children}</div>
            </div>
          )}
          {type === "confirmation" && (
            <div className="flex">
              {Icon && (
                <div className="mr-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-300">
                  <Icon className="h-4 w-4 text-black" />
                </div>
              )}
              <div className="w-full">
                {titleElement
                  ? titleElement
                  : title && <DialogHeader title={title} subtitle={description} />}
                <div className="flex flex-col space-y-6">{children}</div>
              </div>
            </div>
          )}
        </DialogPrimitive.Content>
      </DialogPortal>
    );
  },
);

type DialogHeaderProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  centerTitle?: boolean;
  showCloseButton?: boolean;
};

export function DialogHeader(props: DialogHeaderProps) {
  if (!props.title) return null;

  return (
    <div className="relative mb-10 flex w-full">
      <div
        className={cn("flex w-full flex-col items-start", props.centerTitle ? "items-center" : "")}>
        <Heading
          level={2}
          color="black"
          id="modal-title"
          noMargins
          className="!text-2xl font-semibold">
          {props.title}
        </Heading>
        {props.subtitle && <div className="text-sm text-gray-500">{props.subtitle}</div>}
      </div>
      {props.showCloseButton && (
        <div className="absolute right-0 top-0">
          <DialogPrimitive.DialogClose>
            <X className="text-2xl" />
          </DialogPrimitive.DialogClose>
        </div>
      )}
    </div>
  );
}

export function DialogFooter(props: { children: ReactNode }) {
  return <div className="mt-7 flex justify-end space-x-2">{props.children}</div>;
}

DialogContent.displayName = "DialogContent";

export const DialogTrigger = DialogPrimitive.Trigger;
// export const DialogClose = DialogPrimitive.Close;

export function DialogClose(
  props: {
    dialogCloseProps?: React.ComponentProps<(typeof DialogPrimitive)["Close"]>;
    children?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    color?: ButtonProps["color"];
  } & React.ComponentProps<typeof Button>,
) {
  return (
    <DialogPrimitive.Close asChild {...props.dialogCloseProps}>
      {/* This will require the i18n string passed in */}
      <Button color={props.color ?? "outline"} {...props}>
        {props.children ? props.children : "Chiudi"}
      </Button>
    </DialogPrimitive.Close>
  );
}
