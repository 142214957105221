import { signOut } from "next-auth/react";

import { Button } from "../button";
import { Container } from "../layout";

export type StalkerNavbarProps = {
  admin: {
    adminId: string;
    name: string;
    email: string;
  };
  user: {
    firstName?: string | null;
    lastName?: string | null;
    activeCompanyName?: string | null;
    role: string;
  };
};

export const StalkerNavbar = ({ admin, user }: StalkerNavbarProps) => {
  return (
    <div className="z-index-[1000] w-full bg-red-800 text-white">
      <Container fullwidth>
        <div className="relative inline-flex h-16 w-full flex-row justify-between">
          <div className="flex flex-col justify-center gap-1 text-sm">
            <div className="flex w-full items-center gap-1">
              Connesso come <span className="font-bold">{admin.name}</span>
            </div>
            <span className="italic max-md:hidden">
              Da grandi poteri derivano grandi responsabilità.
            </span>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex flex-col justify-center gap-0 text-sm">
              <span className="font-bold">
                {user.lastName} {user.firstName} ({user.role == "manager" ? "Admin" : "Pax"})
              </span>
              {user.activeCompanyName}
            </div>
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                void signOut({ callbackUrl: "/auth/login" });
              }}>
              Disconnetti
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
