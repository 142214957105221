import type { JSX } from "react";

import { cn } from "@gility/lib";

export type ContainerProps = JSX.IntrinsicElements["div"] & {
  fullwidth?: boolean;
  noPadding?: boolean;
  larger?: boolean;
};

export default function Container({
  children,
  className,
  fullwidth = false,
  larger = false,
  noPadding = false,
  ...props
}: ContainerProps) {
  return (
    <div
      className={cn(
        "mx-auto",
        !noPadding && "px-6 lg:px-8",
        !fullwidth && "container",
        !fullwidth && !larger && "max-w-8xl",
        className,
      )}
      {...props}>
      {children}
    </div>
  );
}
