import { create } from "zustand";

interface IndustriesStore {
  industryFacets?: string[];
  setIndustryFacets: (industries: string[]) => void;
}

const industriesStore = create<IndustriesStore>((set) => ({
  industryFacets: [],
  setIndustryFacets: (industryFacets) => set({ industryFacets }),
}));

export const useIndustries = () => {
  return industriesStore((state) => state);
};
