import { cn } from "@gility/lib";
import { formatCurrencyValue } from "@gility/lib/text";

export type ProductPriceProps = {
  currentPrice: number;
  fullPrice: number;
  invertedColors?: boolean;
  className?: string;
};

const ProductPrice = ({
  currentPrice,
  fullPrice,
  invertedColors = false,
  className,
}: ProductPriceProps) => {
  const discounted = currentPrice.toFixed(4) !== fullPrice.toFixed(4);
  const discountPerc = Math.ceil(((fullPrice - currentPrice) / fullPrice) * 100);

  return (
    <div className={cn("flex gap-2", className)}>
      {discounted && (
        <span
          className={cn(
            "flex w-fit items-center rounded-md px-[2.5px] py-0 text-base",
            invertedColors ? "bg-ice text-green-600" : "bg-green-600 text-white",
          )}>
          {-discountPerc}%
        </span>
      )}
      {discounted && (
        <span className="line-through">
          {formatCurrencyValue(fullPrice, { showDecimals: "ifNeeded" })}
        </span>
      )}
      <b>{formatCurrencyValue(currentPrice, { showDecimals: "ifNeeded" })}</b>
    </div>
  );
};

export default ProductPrice;
