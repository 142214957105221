import { cn } from "@gility/lib";
import { useLocale } from "@gility/lib/hooks/useLocale";

type SkeletonBaseProps = {
  className?: string;
};

type SkeletonContainer = {
  as?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  className?: string;
};

export const SkeletonAvatar: React.FC<SkeletonBaseProps> = ({ className }) => {
  return <div className={cn(`mr-2 mt-1 rounded-full bg-gray-200`, className)} />;
};

type SkeletonProps<T> = {
  as: keyof JSX.IntrinsicElements | React.FC;
  className?: string;
  children: React.ReactNode;
  loading?: boolean;
  waitForTranslation?: boolean;
  loadingClassName?: string;
} & (T extends React.FC<infer P>
  ? P
  : T extends keyof JSX.IntrinsicElements
    ? JSX.IntrinsicElements[T]
    : never);

export const Skeleton = <T extends keyof JSX.IntrinsicElements | React.FC>({
  as,
  className = "",
  children,
  loading = false,
  /**
   * Assumes that the text needs translation by default and wait for it.
   */
  waitForTranslation = true,
  /**
   * Classes that you need only in loading state
   */
  loadingClassName = "",
  ...rest
}: SkeletonProps<T>) => {
  const { isLocaleReady } = useLocale();
  loading = (waitForTranslation ? !isLocaleReady : false) || loading;
  const Component = as;

  return (
    <Component
      className={cn(
        className,
        loading
          ? cn(
              "font-size-0 scale-out-content animate-pulse rounded-md bg-gray-200 text-transparent",
              loadingClassName,
            )
          : "scale-in-content duration-150",
      )}
      {...rest}>
      {children}
    </Component>
  );
};

export const SkeletonText: React.FC<SkeletonBaseProps & { invisible?: boolean }> = ({
  className = "",
  invisible = false,
}) => {
  return (
    <span
      className={cn(
        `font-size-0 inline-block animate-pulse rounded-md bg-gray-200 empty:before:inline-block empty:before:content-['']`,
        className,
        invisible ? "invisible" : "",
      )}
    />
  );
};

export const SkeletonBox: React.FC<SkeletonBaseProps> = ({ className }) => {
  return <div className={cn(`animate-pulse rounded-md bg-gray-200`, className)} />;
};

export const SkeletonContainer: React.FC<SkeletonContainer> = ({ children, as, className }) => {
  const Component = as ?? "div";
  return <Component className={cn("animate-pulse", className)}>{children}</Component>;
};
