import { create } from "zustand";

type Options = {
  companyCatalogLinkEnabled: boolean;
  moxiaCompanyProductsBannerEnabled: boolean;
  moxiaCatalogBannerEnabled: boolean;
};

type Store = {
  options: Options;
  ready: boolean;
};

type Action = {
  setOptions: (options: Options) => void;
  dismissMoxiaCatalogBanner: () => void;
  dismissMoxiaCompanyProductsBanner: () => void;
  setReady: (ready: boolean) => void;
};

const optionsStore = create<Store & Action>((set) => ({
  options: {
    companyCatalogLinkEnabled: false,
    moxiaCatalogBannerEnabled: false,
    moxiaCompanyProductsBannerEnabled: false,
  },
  setOptions: (options) => set({ options }),
  dismissMoxiaCatalogBanner: () =>
    set((state) => ({ ...state, options: { ...state.options, moxiaCatalogBannerEnabled: false } })),
  dismissMoxiaCompanyProductsBanner: () =>
    set((state) => ({
      ...state,
      options: { ...state.options, moxiaCompanyProductsBannerEnabled: false },
    })),
  ready: false,
  setReady: (ready) => set({ ready }),
}));

export const useOptions = () => {
  return optionsStore((state) => state);
};
