import { create } from "zustand";

interface CartStore {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (open: boolean) => void;
  openDropdownForASecond: () => void;
}

const cartStore = create<CartStore>((set) => ({
  isDropdownOpen: false,
  setIsDropdownOpen: (open) => set({ isDropdownOpen: open }),
  openDropdownForASecond: () => {
    set({ isDropdownOpen: true });
    setTimeout(() => set({ isDropdownOpen: false }), 3000);
  },
}));

export const useCartStore = () => {
  return cartStore((state) => state);
};
