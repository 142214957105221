import { type ReactElement } from "react";
import { EMAILS_GILITY_SUPPORT_EMAIL } from "@gility/lib";
import { Button, Dialog, DialogContent, Heading } from "@gility/ui";

export type AssistenzaDialogProps = JSX.IntrinsicElements["div"] & {
  open: boolean;
  onClose?: () => void;
};

const AssistenzaDialog = ({ open, onClose }: AssistenzaDialogProps): ReactElement => {
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open && onClose) onClose();
      }}>
      <DialogContent enableOverflow className="w-full text-center">
        <Heading level={3} className="!mt-0" color="black">
          Hai bisogno di assistenza?
        </Heading>
        <div className="flex flex-col items-center justify-center gap-0">
          <span>Scrivici</span>
          <Button color="link" href={`mailto:${EMAILS_GILITY_SUPPORT_EMAIL}`} className="m-0 mb-4">
            {EMAILS_GILITY_SUPPORT_EMAIL}
          </Button>
          <span>
            Il nostro team è sempre pronto a offrirti supporto
            <br />
            per risolvere ogni tuo dubbio.
          </span>

          <div>
            <Button
              onClick={() => onClose?.()}
              color="primary"
              size="lg"
              className="mt-4 flex min-w-[120px] flex-col items-center">
              Ok
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AssistenzaDialog;
