export const truncate = (text: string, maxLength: number, ellipsis = true) => {
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength - 3)}${ellipsis ? "..." : ""}`;
};

export const truncateOnWord = (text: string, maxLength: number, ellipsis = true) => {
  if (text.length <= maxLength) return text;

  // First split on maxLength chars
  let truncatedText = text.substring(0, 148);
  // Then split on the last space, this way we split on the last word,
  // which looks just a bit nicer.
  truncatedText = truncatedText.substring(
    0,
    Math.min(truncatedText.length, truncatedText.lastIndexOf(" ")),
  );
  if (ellipsis) truncatedText += "...";

  return truncatedText;
};

export const formatCurrencyValue = (
  value: number,
  options: {
    currency?: string;
    locale?: string;
    showDecimals?: "always" | "never" | "ifNeeded";
  } = {
    currency: "EUR",
    locale: "it",
    showDecimals: "always",
  },
) => {
  const { currency = "EUR", locale = "it", showDecimals = "always" } = options;

  let minimumFractionDigits = 2;
  if (showDecimals === "never") minimumFractionDigits = 0;
  else if (showDecimals === "always") minimumFractionDigits = 2;
  else minimumFractionDigits = Math.round(value) == value ? 0 : 2;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(value);
};

export const formatTimeToString = (
  minutesTime: number | undefined | null,
  format: "time" | "text" | "text-short" = "time",
) => {
  if (!minutesTime || isNaN(minutesTime)) {
    return undefined;
  }

  if (format === "text" || format === "text-short") {
    const minutes = minutesTime % 60;
    const hours = Math.round((minutesTime - minutes) / 60);
    const short = format === "text-short";

    const minText = minutes > 0 ? (short ? `${minutes}m` : `${minutes} min`) : null;
    let hrsText =
      hours > 0 ? (short ? `${hours}h` : `${hours} ${hours == 1 ? "ora" : "ore"}`) : null;
    if (minutes == 0 && hours == 0) {
      hrsText = short ? "0h" : "0 ore";
    }
    return [hrsText, minText].filter((x) => x).join(" ");
  } else {
    const minutes = minutesTime % 60;
    const hours = Math.round((minutesTime - minutes) / 60);
    const formattedHours = (hours < 10 ? "0" : "") + hours;
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
