import type { JSX } from "react";
import Link from "next/link";

import { cn } from "@gility/lib";
import { COMPANY_FULLNAME, WWW_APP_URL } from "@gility/lib/constants";

import { ChevronRight } from "../icon";
import { Container } from "../layout";
import { Logo } from "../logo";
import { type NavElement } from "../navbar";

type FooterVariant = "learn" | "www";

export type FooterProps = JSX.IntrinsicElements["div"] & {
  variant?: FooterVariant;
  color?: "light" | "dark";
  elements?: NavElement[];
};

type FooterLinkProps = JSX.IntrinsicElements["a"] & {
  href: string;
  variant?: FooterVariant;
  icon?: boolean;
};

export const FooterLink = ({
  className,
  href,
  children,
  rel,
  icon = true,
  ...props
}: FooterLinkProps) => (
  <Link
    href={href}
    rel={rel}
    className={cn(
      "group flex items-center text-sm leading-6",
      props.variant === "learn" ? "text-blue" : "text-white",
      className,
    )}
    {...props}>
    {children}
    {icon && (
      <ChevronRight
        className="-mt-[1px] ml-[4px] transition-transform duration-300 ease-in-out group-hover:translate-x-1"
        size={20}
      />
    )}
  </Link>
);

export const CompanyInfo = ({
  className,
  ...props
}: { variant: FooterVariant } & JSX.IntrinsicElements["p"]) => (
  <p
    className={cn(
      "text-sm font-light leading-5",
      props.variant === "learn" ? "text-blue" : "text-white",
      className,
    )}
    {...props}>
    {/* {APP_NAME} © 2022-{new Date().getFullYear()} -  */}
    {COMPANY_FULLNAME}
    <br />
    P.IVA IT12470950960 | REA. MI - 2663782. | Via Antonio Locatelli 4, Milano, 20124
  </p>
);

export const LegalLinks = ({ className, ...props }: JSX.IntrinsicElements["div"]) => {
  return (
    <div className={cn("mb-8 flex flex-wrap gap-4", className)} {...props}>
      <FooterLink className="leading-0 text-[#809DB8]" href="/privacy-policy">
        Privacy Policy
      </FooterLink>
      <FooterLink className="leading-0 text-[#809DB8]" href="/termini-e-condizioni">
        Termini e Condizioni
      </FooterLink>
      <FooterLink className="leading-0 text-[#809DB8]" href="/cookie-policy">
        Cookie Policy
      </FooterLink>
      <FooterLink className="leading-0 text-[#809DB8]" href="/compliance" target="_blank">
        Compliance
      </FooterLink>
      <FooterLink className="leading-0 text-[#809DB8]" href="/relazione-d-impatto">
        Relazione d&apos;impatto
      </FooterLink>
    </div>
  );
};

export const Footer = ({ className, children, ...props }: FooterProps) => (
  <footer
    className={cn("relative bg-white", className)}
    aria-labelledby="footer-heading"
    {...props}>
    <Container className="py-6" fullwidth>
      <div className="items-middle flex flex-col gap-6 md:flex-row">
        <Link href="/" className="lg:mr-12">
          <Logo className="-mb-[5px] mt-[5px] h-8" />
        </Link>
        <CompanyInfo className="md:flex-grow" variant="learn" />
        <ul role="list" className="flex flex-row items-center gap-4">
          <li>
            <FooterLink
              target="_blank"
              className="!leading-0 inline-block"
              href={`${WWW_APP_URL}/privacy-policy`}
              variant="learn"
              icon={false}>
              Privacy Policy
            </FooterLink>
          </li>
          <li>
            <FooterLink
              target="_blank"
              className="!leading-0 inline-block"
              href={`${WWW_APP_URL}/termini-e-condizioni`}
              variant="learn"
              icon={false}>
              Termini e Condizioni
            </FooterLink>
          </li>
          <li>
            <FooterLink
              target="_blank"
              className="!leading-0 inline-block"
              href={`${WWW_APP_URL}/cookie-policy`}
              variant="learn"
              icon={false}>
              Cookie Policy
            </FooterLink>
          </li>
        </ul>
      </div>
    </Container>
    {children}
  </footer>
);
