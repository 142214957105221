import { cn } from "@gility/lib";

const blueLogo = "/assets/gility-logo-blue-word.svg";
const whiteLogo = "/assets/gility-logo-white-word.svg";

const title = "Gility formazione per aziende";

export type LogoProps = {
  small?: boolean;
  icon?: boolean;
  className?: string;
  color?: "white" | "blue";
};

export default function Logo({ small, icon, className, color }: LogoProps) {
  return (
    <>
      {icon ? (
        <img
          className={cn("mx-auto w-9 text-amber-600", className)}
          alt={title}
          title={title}
          src={color == "white" ? whiteLogo : blueLogo}
        />
      ) : (
        <img
          className={cn(small ? "h-4 w-auto" : "h-8 w-auto", className)}
          alt={title}
          title={title}
          src={color == "white" ? whiteLogo : blueLogo}
        />
      )}
    </>
  );
}
