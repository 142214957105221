export const G = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="99"
      height="145"
      viewBox="0 0 99 145"
      fill="none"
      style={{ left: "351px", top: "165px" }}>
      <path
        d="M49.418 74.66c-8.51.031-16.19-.623-23.718-2.74C9.694 67.407 1.113 56.75.48 40.106.059 29.15 2.023 18.85 10.502 10.923c5.932-5.554 13.357-8.06 21.15-9.36C44.53-.595 57.438-.605 70.18 2.505c20.476 4.991 28.3 19.363 26.602 39.76-.838 10.106-4.694 18.77-13.51 24.58-5.493 3.622-11.66 5.453-18.073 6.578-5.471.961-10.984 1.289-15.791 1.248l.01-.01Zm-2.23-14.627c4.91.03 8.377-.307 11.813-.982 8.172-1.606 13.757-6.045 15.485-14.515.9-4.378.93-8.777.184-13.185-1.309-7.713-5.574-12.828-13.194-14.884-8.386-2.27-16.844-2.26-25.221-.092-7.691 1.995-12.14 7.089-13.429 14.955-.695 4.245-.685 8.45.092 12.684 1.401 7.652 5.87 12.439 13.327 14.485 4.04 1.115 8.182 1.493 10.954 1.534h-.01ZM38.778 89.567c11.028 0 22.057-.062 33.085.041 3.63.031 7.188.92 10.594 2.193 12.555 4.705 16.821 14.653 15.891 26.773-.819 10.734-6.864 17.393-16.346 21.168-9.219 3.681-18.913 4.746-28.688 5.067-15.365.496-30.579-.703-45.56-4.488-2.729-.693-3.891-1.686-3.649-4.757.324-4.198.132-8.438.05-12.657-.03-1.376.405-1.603 1.588-1.231 12.828 4.012 26.04 5.284 39.353 5.491 8.218.124 16.507.093 24.584-1.944 3.71-.941 6.682-2.865 7.319-7.177.667-4.467-1.284-7.828-5.47-9.193-3.406-1.117-6.944-1.22-10.482-1.272-12.029-.176-24.078.507-36.098-.403-5.772-.434-11.291-1.924-15.91-5.791-2.902-2.43-4.64-5.574-5.5-9.317-.465-2.038-.172-2.792 2.153-2.761 11.029.134 22.057.062 33.096.062v.206l-.01-.01Z"
        fill="currentColor"></path>
    </svg>
  );
};

export default G;
