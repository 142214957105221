import {
  ADMIN_APP_URL,
  GCS_BUCKET_NAME,
  LEARN_APP_URL,
  LEGACY_APP_URL,  
  WWW_APP_URL,
} from "./constants";

const prependPathWithSeparator = (path: string): string =>
  path.startsWith("/") ? path : `/${path}`;

export const legacyLearnAppUrl = (partialUrl: string): string => {
  return `${LEGACY_APP_URL}${prependPathWithSeparator(partialUrl)}`;
};

export const adminAppUrl = (partialUrl: string): string => {
  return `${ADMIN_APP_URL}${prependPathWithSeparator(partialUrl)}`;
};

export const googleStorageLegacyAssetUrl = (id: string): string => {
  return `https://storage.googleapis.com/${GCS_BUCKET_NAME}/${id}`;
};

export const learnAppUrl = (endpoint: string) =>
  `${LEARN_APP_URL}${prependPathWithSeparator(endpoint)}`;

export const learnApiPath = (endpoint: string) =>
  `${LEARN_APP_URL}/api${prependPathWithSeparator(endpoint)}`;

export const wwwAppUrl = (endpoint: string) =>
  `${WWW_APP_URL}${prependPathWithSeparator(endpoint)}`;
