import toast from "react-hot-toast";

import { Heading } from "../heading";
import { AlertTriangle, Ban, Check } from "../icon";

type IToast = {
  title?: string;
  message: string;
  toastVisible: boolean;
};

export const SuccessToast = ({ message, title }: IToast) => {
  return (
    <div
      data-testid="toast-success"
      className="mb-2 flex h-auto max-w-[400px] items-start space-x-2 rounded-md bg-[#00B140] p-3 font-sans text-white shadow-md animate-in slide-in-from-top">
      <Check size={16} className="mt-1 min-h-[16px] min-w-[16px]" />
      <div>
        {title && (
          <Heading size={4} noMargins color="white">
            {title}
          </Heading>
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </div>
  );
};

export const ErrorToast = ({ message, title }: IToast) => {
  return (
    <div
      data-testid="toast-error"
      className="mb-2 flex h-auto max-w-[400px] items-start space-x-2 rounded-md bg-[#DC2626] p-3 font-sans text-white shadow-md animate-in slide-in-from-top">
      <Ban size={16} className="mt-1 min-h-[16px] min-w-[16px]" />
      <div>
        {title && (
          <Heading size={4} noMargins color="white">
            {title}
          </Heading>
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </div>
  );
};

export const WarningToast = ({ message, title }: IToast) => {
  return (
    <div
      data-testid="toast-warning"
      className="mb-2 flex h-auto max-w-[400px] items-start space-x-2 rounded-md bg-orange-500 p-3 font-sans text-white shadow-md animate-in slide-in-from-top md:max-w-lg">
      <AlertTriangle size={16} className="mt-1 min-h-[16px] min-w-[16px]" />
      <div>
        {title && (
          <Heading size={4} noMargins color="white">
            {title}
          </Heading>
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </div>
  );
};

export const DefaultToast = ({ message, title }: IToast) => {
  return (
    <div
      data-testid="toast-default"
      className="mb-2 flex h-auto max-w-[400px] items-start space-x-2 rounded-md bg-blue-600 p-3 font-sans text-white shadow-md animate-in slide-in-from-top md:max-w-lg">
      <Check size={16} className="mt-1 min-h-[16px] min-w-[16px]" />
      <div>
        {title && (
          <Heading size={4} noMargins color="white">
            {title}
          </Heading>
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </div>
  );
};

export function showToast(
  message: string,
  variant?: "success" | "warning" | "error",
  duration = 6000,
  title?: string,
) {
  switch (variant) {
    case "success":
      toast.custom(
        (t) => <SuccessToast message={message} toastVisible={t.visible} title={title} />,
        {
          duration,
          position: "top-right",
        },
      );
      break;
    case "error":
      toast.custom((t) => <ErrorToast message={message} toastVisible={t.visible} title={title} />, {
        duration,
        position: "top-right",
      });
      break;
    case "warning":
      toast.custom(
        (t) => <WarningToast message={message} toastVisible={t.visible} title={title} />,
        {
          duration,
          position: "top-right",
        },
      );
      break;
    default:
      toast.custom(
        (t) => <DefaultToast message={message} toastVisible={t.visible} title={title} />,
        {
          duration,
          position: "top-right",
        },
      );
      break;
  }
}
