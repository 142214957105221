import { useEffect, useState } from "react";

import { isServer } from "../browser/browser.utils";

const useResponsive = () => {
  const [width, setWidth] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (!isServer) {
      setWidth(window.innerWidth);
      setIsReady(true);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    isReady,
    width,
    isSm: width <= 640,
    isMd: width > 640 && width <= 768,
    isLg: width > 768 && width <= 1024,
    isXl: width > 1024 && width <= 1280,
    is2xl: width > 1280 && width < 1536,
  };
};

export default useResponsive;
