import React, { type ReactNode } from "react";
import { cn } from "@gility/lib";

import { Button } from "../button";
import { Pencil } from "../icon";
import { Skeleton } from "../skeleton";

export type HeadingColor = "default" | "white" | "pink" | "blue" | "black" | "gray";

export type HeadingProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children?: React.ReactNode;
  className?: string;
  subHeadingClassName?: string;
  color?: HeadingColor;
  id?: string;
  overline?: ReactNode;
  subHeading?: ReactNode;
  actions?: ReactNode;
  actionsAlign?: "start" | "end";
  isLoading?: boolean;
  noMargins?: boolean;
  bigger?: boolean;
  size?: number;
  editable?: () => void;
};

export default function Heading({
  children,
  className,
  subHeadingClassName,
  level = 1,
  id = undefined,
  overline,
  color = "default",
  subHeading,
  actions,
  actionsAlign = "end",
  isLoading = false,
  noMargins = false,
  bigger = false,
  size = level,
  editable,
}: HeadingProps) {
  const Header = `h${level}` as keyof JSX.IntrinsicElements;
  let fontSize;
  let fontWeight = "font-bold";
  let margins = "my-5";

  switch (bigger ? size - 1 : size) {
    case 0:
      fontSize = "text-[2.5rem] md:text-[3.5rem] leading-[3rem] md:leading-[4rem]";
      margins = "my-8 lg:my-10";
      break;
    case 1:
      fontSize = "text-3xl md:text-4xl";
      margins = "my-8 lg:my-10";
      break;
    case 2:
      fontSize = "text-2xl md:text-3xl";
      margins = "my-8 lg:my-10";
      break;
    case 3:
      fontSize = "text-lg md:text-xl";
      fontWeight = "font-semibold";
      break;
    case 4:
      fontSize = "text-md md:text-lg";
      break;
    case 5:
    case 6:
      fontSize = "text-md";
      break;
  }

  return (
    <div
      className={cn(
        (overline ?? subHeading) && "flex flex-col",
        // Margins
        (overline ?? subHeading) && !noMargins && margins,
      )}>
      <div
        className={cn(
          "flex flex-row flex-nowrap items-baseline gap-2",
          actionsAlign == "end" ? "justify-between" : "items-center",
        )}>
        <div
          className={cn(
            "flex-0 flex flex-col flex-nowrap",
            actionsAlign == "start" ? "flex-0" : "flex-1",
          )}>
          {overline && <div className="text-gray-500">{overline}</div>}
          <Skeleton
            loading={isLoading}
            as={Header}
            id={id}
            loadingClassName="lg:w-9/12"
            className={cn(
              // Font size
              // Margins
              overline || subHeading || noMargins ? "" : margins,
              fontWeight,
              fontSize,
              color == "white" && "text-white",
              color == "pink" && "text-pink",
              color == "blue" && "text-blue",
              color == "black" && "text-black",
              color == "gray" && "text-black/80",
              color == "default" && (level == 1 || level == 5 ? "text-pink" : "text-blue"),
              // Custom overline margins
              (overline ?? subHeading) && !noMargins && "mt-2 sm:mt-2 lg:mt-2",
              // Custom classes
              className,
            )}>
            {editable ? (
              <div className="flex items-center">
                {children ? children : "..."}
                <Button color="ghost" onClick={editable}>
                  <Pencil size={14} className="text-gray-400" />
                </Button>
              </div>
            ) : (
              <>{children ? children : "..."}</>
            )}
          </Skeleton>
          {subHeading && (
            <Skeleton
              as="div"
              loading={isLoading}
              className={cn("mt-2", subHeadingClassName)}
              loadingClassName="lg:w-8/12">
              {subHeading}
            </Skeleton>
          )}
        </div>
        {actions && (
          <div className="flex-0">
            <Skeleton as="div" loading={isLoading}>
              {actions}
            </Skeleton>
          </div>
        )}
      </div>
    </div>
  );
}
