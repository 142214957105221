// import React, { useCallback, useState } from "react";

import { useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { EmptySearch } from "@components/catalog/EmptySearch";
import ProductPrice from "@components/catalog/ProductPrice";
import { cn } from "@gility/lib";
import { parseImageSrc } from "@gility/lib/docebo";
import useResponsive from "@gility/lib/hooks/useResponsive";
import { learnAppUrl } from "@gility/lib/paths";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  LiveFlag,
} from "@gility/ui";
import { ShoppingCart } from "@gility/ui/components/icon";

import { api } from "~/utils/api";
import { useCartStore } from "~/lib/cart-store";

export const CartDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDropdownOpen: isGloballyOpen, setIsDropdownOpen: setIsGloballyOpen } = useCartStore();
  const { push } = useRouter();
  const { isSm, isMd, isLg } = useResponsive();
  const isLgOrBelow = isSm || isMd || isLg;

  const { data: cart } = api.cart.get.useQuery(undefined, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <DropdownMenu
      open={isOpen || isGloballyOpen}
      onOpenChange={(open) => {
        if (isLgOrBelow) {
          // we perform a full page redirect because if the user is already on the cart page when clicking the cart button,
          // the mobile collapsible menu will remain open and nothing would happen
          window.location.href = learnAppUrl("/checkout/cart");
        } else {
          setIsOpen(open);
          setIsGloballyOpen(open);
        }
      }}>
      <DropdownMenuTrigger asChild className="flex gap-2">
        <Button
          id="cart-btn"
          color="ghost"
          className={cn(
            "radix-state-active:text-red group/trigger p-4",
            "cursor-pointer appearance-none items-center rounded  text-left outline-none",
            "text-blue hover:bg-transparent hover:text-pink hover:opacity-100 focus:outline-none focus:ring-0",
          )}
          size="sm">
          <ShoppingCart className="h-5 w-5" />
          <span className="text-base lg:hidden">Carrello</span>
          <span
            className={cn(
              "absolute right-0 top-0 hidden h-4 w-4 -translate-x-1/2 translate-y-1/4 select-none rounded-full bg-pink text-[8px] font-semibold text-white lg:translate-x-1/4",
              cart && cart.lineItems.length > 0 && "flex items-center justify-center",
            )}>
            {cart && cart.lineItems.length < 10 ? cart.lineItems.length : "9+"}
          </span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-[500px] py-4">
        <div>
          {!cart || cart.lineItems.length < 1 ? (
            <div className="flex h-full items-center justify-center">
              <EmptySearch mainText="Il carrello è vuoto" />
            </div>
          ) : (
            <>
              <div className="max-h-[400px] overflow-y-scroll">
                {cart.lineItems.map((li, i) => (
                  <div key={li.id} className="px-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-start gap-4">
                        <Image
                          src={parseImageSrc(li.product?.imgUrl) ?? ""}
                          alt={"Cover del corso"}
                          width={100}
                          height={100}
                          placeholder="empty"
                          className="hidden min-h-[64px] min-w-[128px] rounded-lg md:block"
                        />
                        <div className="line-clamp-2">{li.product?.name}</div>
                        {li.product?.live && <LiveFlag />}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-end">
                      <ProductPrice currentPrice={li.finalNetPrice} fullPrice={li.fullNetPrice} />
                      <span>IVA esclusa</span>
                    </div>
                    {i < cart.lineItems.length - 1 && <hr className="my-4" />}
                  </div>
                ))}
              </div>
              {cart.lineItems.length > 0 && (
                <>
                  <hr className="my-4" />

                  <div className="p-4">
                    <div className="flex items-start justify-between pb-4">
                      <div className="text-lg font-semibold">Totale</div>
                      <div className="flex flex-col">
                        <ProductPrice
                          currentPrice={cart.finalNetPrice}
                          fullPrice={cart.finalNetPrice}
                          // double finalNetPrice to don't show discount (from design)
                          className="self-end text-lg"
                        />
                        <span className="self-end">IVA esclusa</span>
                      </div>
                    </div>
                    <Button
                      data-testid="go-to-cart"
                      className="mt-auto flex w-full justify-center font-semibold"
                      onClick={() => {
                        void push(learnAppUrl("/checkout/cart"));
                        setIsOpen(false);
                        setIsGloballyOpen(false);
                      }}
                      disabled={cart.lineItems.length === 0}>
                      Vai al carrello
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
