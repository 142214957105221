import { useEffect, useState, type ReactNode } from "react";
import Link from "next/link";
import { AccordionTrigger } from "@radix-ui/react-accordion";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { cn } from "@gility/lib";
import { gilityEnv } from "@gility/lib/env";
import useResponsive from "@gility/lib/hooks/useResponsive";

import { ChevronDown, Dot, Menu, X } from "../icon";
import { Container } from "../layout";
import { Logo } from "../logo";
import { Pill } from "../pill";

type User = {
  role?: string | null;
};

export type NavElementType = {
  name?: ReactNode;
  href: string;
  rel?: string;
  trigger?: boolean;
  isCurrent?: ({ path }: { path: string; item: NavElementType; isChild?: boolean }) => boolean;
  requiredRole?: string;
  requiredFeatureFlag?: string;
  requiredOption?: string;
  children?: NavElementType[];
  content?: ReactNode;
  fullWidth?: boolean;
  icon?: ReactNode;
};

const defaultIsCurrent: NavElementType["isCurrent"] = ({ path, item, isChild }) => {
  return isChild
    ? item.href === path
    : item.children
      ? item.children.some((item) => path.startsWith(item.href))
      : path.startsWith(item.href);
};

const NavElement = ({
  item,
  isChild,
  path,
  user,
  colorVariant,
  featureFlags,
  options,
}: {
  item: NavElementType;
  isChild?: boolean;
  path: string;
  user?: User;
  featureFlags?: Record<string, boolean>;
  options?: Record<string, boolean>;
  colorVariant: "blue" | "white";
}) => {
  const isCurrent: NavElementType["isCurrent"] = item.isCurrent ?? defaultIsCurrent;
  const current = isCurrent({ isChild: !!isChild, item, path });

  const isRoleSatisfied = !item.requiredRole || (user && item.requiredRole === user.role);
  const isFeatureFlagSatisfied =
    !item.requiredFeatureFlag || (featureFlags && featureFlags[item.requiredFeatureFlag] === true);
  const isOptionSatisfied =
    !item.requiredOption || (options && options[item.requiredOption] === true);

  const shouldDisplayNavigationItem =
    isRoleSatisfied && isFeatureFlagSatisfied && isOptionSatisfied;

  if (!shouldDisplayNavigationItem) return null;

  const isBlue = colorVariant == "blue";

  const shouldDisplayContent = !!item.children || !!item.content;

  return shouldDisplayContent ? (
    <>
      <NavigationMenu.Trigger
        onPointerMove={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
        className={cn(
          "flex items-center p-4",
          isBlue ? "text-white hover:text-gray-300" : "text-blue hover:text-pink",
        )}>
        <div
          className="flex items-center gap-2 rounded-md text-base font-normal"
          aria-current={current ? "page" : undefined}
          data-testid="nav-dropdown-trigger">
          <span className={cn(current && (isBlue ? "font-bold" : "text-pink"))}>{item.name}</span>
          {item.name === "Corsi" && <Pill className="bg-pink font-bold text-white" text="NEW" />}
          <ChevronDown className="h-5 w-5" />
        </div>
      </NavigationMenu.Trigger>
      <NavigationMenu.Content
        onPointerEnter={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
        className={cn(
          item.fullWidth ? "fixed left-0 w-[100vw]" : "absolute min-w-[16rem] rounded-md",
          !item.fullWidth && "w-radix-navigation-menu-viewport",
          "z-20 mt-0 overflow-hidden bg-white shadow-lg",
          "h-radix-navigation-menu-viewport",
          "radix-state-open:animate-scale-in-content",
          "radix-state-closed:animate-scale-out-content",
          "origin-[top_center] transition-[width_height]",
          "duration-300 ease-in",
        )}>
        {item.content ? (
          item.content
        ) : item.children ? (
          <div className="min-w-[16rem] p-3">
            {item.children.map((item, idx) => (
              <NavElement
                item={item}
                path={path}
                user={user}
                featureFlags={featureFlags}
                options={options}
                key={`item-${idx}`}
                colorVariant={colorVariant}
                isChild
              />
            ))}
          </div>
        ) : null}
      </NavigationMenu.Content>
    </>
  ) : (
    <NavLink isBlue={isBlue} current={current} item={item} isChild={isChild} />
  );
};

export const NavLink = ({
  item,
  isBlue,
  current,
  onClick,
  isChild = false,
}: {
  item: NavElementType;
  isBlue: boolean;
  current: boolean;
  isChild?: boolean;
  onClick?: (evt: React.MouseEvent) => void;
}) => {
  return (
    <div className="flex items-center">
      <Link
        href={item.href}
        onClick={onClick}
        className={cn(
          "flex items-center gap-2 rounded-md p-4",
          isBlue ? "text-white hover:text-gray-300" : "text-blue hover:text-pink",
          isChild && "text-gray-600",
          current && !isBlue && "text-pink",
          current && isBlue && !isChild && "font-bold text-white",
          current && isBlue && isChild && "font-bold text-gray-600",
        )}
        aria-current={current ? "page" : undefined}>
        {item.icon}
        {item.trigger ? (
          <AccordionTrigger className="!font-light">{item.name}</AccordionTrigger>
        ) : (
          item.name
        )}
        {item.name === "Caricati dalla tua azienda" && (
          <Dot className="-mx-2 text-pink" size={42} />
        )}
      </Link>
    </div>
  );
};

type NavigationProps = {
  elements?: NavElementType[];
  path: string;
  user?: User;
  featureFlags?: Record<string, boolean>;
  options?: Record<string, boolean>;
  colorVariant: "blue" | "white";
};

const Navigation = ({
  elements,
  path,
  user,
  colorVariant,
  featureFlags,
  options,
}: NavigationProps) => (
  <NavigationMenu.Root className="relative flex" delayDuration={0}>
    <NavigationMenu.List className="relative flex flex-col lg:flex-row">
      {elements?.map((item, idx) => (
        <NavigationMenu.Item key={`item-${idx}`}>
          <NavElement
            item={item}
            path={path}
            user={user}
            colorVariant={colorVariant}
            featureFlags={featureFlags}
            options={options}
          />
        </NavigationMenu.Item>
      ))}
      <NavigationMenu.Indicator
        className={cn(
          "z-10",
          "top-[100%] flex flex-col items-center justify-center overflow-hidden",
          "radix-state-visible:animate-fade-in",
          "radix-state-hidden:animate-fade-out",
          "duration-250 transition-[width_transform] ease-in",
        )}>
        <div className="relative top-1 h-2 w-2 rotate-45 bg-white dark:bg-gray-800" />
      </NavigationMenu.Indicator>
    </NavigationMenu.List>
  </NavigationMenu.Root>
);

type NavbarProps = {
  fullwidth: boolean;
  children?: ReactNode;
  elements?: NavElementType[];
  user?: User;
  featureFlags?: Record<string, boolean>;
  options?: Record<string, boolean>;
  path: string;
  logoHref?: string;
  scrolling?: boolean;
  collapsible?: boolean;
  className?: string;
  colorVariant?: "blue" | "white";
};

export const Navbar = ({
  fullwidth,
  children,
  elements,
  path,
  user,
  featureFlags,
  options,
  className,
  scrolling = false,
  collapsible = true,
  logoHref = "/",
  colorVariant = "blue",
}: NavbarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const { isSm, isMd, isLg } = useResponsive();
  const isLgOrBelow = isSm || isMd || isLg;

  useEffect(() => {
    const didScrollPage = () => {
      setHasScrolled(window.scrollY > 200);
    };

    window.addEventListener("scroll", didScrollPage);

    return () => {
      window.removeEventListener("keydown", didScrollPage);
    };
  }, []);

  const navbarContent = (
    <>
      {gilityEnv.is(["development", "alpha", "staging", "demo"]) && (
        <div className="pointer-events-none absolute left-0 top-0 z-20 h-24 w-16 opacity-80">
          <div className="absolute left-[-76px] top-[-28px] w-[170px] -rotate-45 transform bg-pink pb-1 pt-10 text-center font-semibold text-white">
            {gilityEnv.dev() ? "dev" : gilityEnv.raw}
          </div>
        </div>
      )}
      <Container className="relative" fullwidth={fullwidth}>
        <div className="relative flex h-16 items-center justify-between">
          {collapsible && (
            <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
              {/* Mobile menu button*/}
              <CollapsiblePrimitive.Trigger className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Apri Menu principale</span>
                {isOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </CollapsiblePrimitive.Trigger>
            </div>
          )}
          <div
            className={cn(
              "flex flex-1 text-base lg:items-stretch lg:justify-start",
              collapsible && "items-center justify-center",
            )}>
            <Link className="flex flex-shrink-0 items-center" href={logoHref}>
              <Logo color={colorVariant == "blue" ? "white" : "blue"} />
            </Link>
            <div className={cn("lg:ml-6 lg:block", collapsible && "hidden")}>
              <div className="flex space-x-4">
                <Navigation
                  elements={elements && elements}
                  path={path}
                  user={user}
                  featureFlags={featureFlags}
                  options={options}
                  colorVariant={colorVariant}
                />
              </div>
            </div>
          </div>
          {children && (
            <div
              className={cn(
                "absolute inset-y-0 right-0 flex items-center space-x-4 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 lg:block",
                collapsible && "hidden",
              )}>
              {children}
            </div>
          )}
        </div>
      </Container>
    </>
  );

  const navbarContainerClassName = cn(
    "w-full group relative",
    colorVariant == "blue"
      ? "bg-blue text-white"
      : "bg-white text-blue shadow-[0px_0px_40px_0px_#00000012] rounded-ee-2xl rounded-es-2xl",
    scrolling && "transition-all duration-500",
    scrolling && hasScrolled && "bg-opacity-80 backdrop-blur shadow-md",
    className,
  );

  if (collapsible) {
    return (
      <header className={cn("z-[1000] w-full", scrolling ? "fixed" : "relative")}>
        <CollapsiblePrimitive.Root
          open={isOpen}
          onOpenChange={setIsOpen}
          className={navbarContainerClassName}>
          <>
            {navbarContent}
            {isLgOrBelow && isOpen && (
              <CollapsiblePrimitive.Content
                forceMount
                className="relative bg-transparent lg:hidden">
                <div
                  className={cn(
                    colorVariant == "blue" ? "bg-blue" : "bg-white",
                    "absolute z-50 h-[calc(100dvh-64px)] w-full transition-all duration-300",
                    "group-radix-state-closed:-left-full group-radix-state-closed:opacity-0 group-radix-state-open:left-0 group-radix-state-open:opacity-100",
                  )}>
                  <Navigation
                    elements={elements}
                    path={path}
                    user={user}
                    featureFlags={featureFlags}
                    options={options}
                    colorVariant={colorVariant}
                  />
                  {children && (
                    <div className="my-4 flex flex-1 items-center gap-4">{children}</div>
                  )}
                </div>
              </CollapsiblePrimitive.Content>
            )}
          </>
        </CollapsiblePrimitive.Root>
      </header>
    );
  } else {
    return (
      <header className={cn("z-[1000] w-full", scrolling ? "fixed" : "relative")}>
        <div className={navbarContainerClassName}>{navbarContent}</div>
      </header>
    );
  }
};
