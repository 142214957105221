import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import AssistenzaDialog from "@components/assistenza/AssistenzaDialog";
import { CartDropdown } from "@components/cart/CartDropdown";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { type Session, type SessionAdmin, type User } from "next-auth";
import { signOut, useSession } from "next-auth/react";
import { usePostHog } from "posthog-js/react";

import type { NavElement } from "@gility/ui";
import { cn, gilityEnv } from "@gility/lib";
import { APP_DESCRIPTION, APP_NAME, APP_PAYOFF, LEARN_APP_URL } from "@gility/lib/constants";
import { learnAppUrl } from "@gility/lib/paths";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  Footer,
  G,
  Navbar,
  NavLink,
  showToast,
  StalkerNavbar,
} from "@gility/ui";
import { Book, Building, Check, Headset, LogOut, UserRound } from "@gility/ui/components/icon";

import { useFeatureFlags } from "~/lib/feature-flags-store";
import { useIndustries } from "~/lib/industries-store";
import { useOptions } from "~/lib/options-store";
import { api } from "~/utils/api";

const useRedirectToLoginIfUnauthenticated = (isPublic = false) => {
  const { status, data: naSession } = useSession();
  const naLoading = status === "loading";

  const { data: session, isLoading } = api.auth.getSession.useQuery(undefined, {
    enabled: naSession === null,
    retry: (failureCount: number) => {
      return failureCount < 5;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const router = useRouter();

  useEffect(() => {
    if (isPublic) {
      return;
    }

    if (!isLoading && !naLoading && !naSession && !session) {
      console.warn("[!] Redirecting to login");

      void router.replace({
        pathname: "/auth/login",
        query: {
          callbackUrl: `${LEARN_APP_URL}${location.pathname}${location.search}`,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, isLoading, naLoading, naSession, isPublic]);

  return {
    loading: naLoading && !session,
    status,
    session: naSession,
  };
};

const useGlobalStores = (session: Session | null) => {
  const featureFlags = useFeatureFlags();
  const industries = useIndustries();
  const options = useOptions();

  api.userCompany.getFlagsIndustriesAndOptions.useQuery(undefined, {
    enabled: !!session,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      featureFlags.setFlags(data.flags);
      industries.setIndustryFacets(data?.industries.map((industry) => `industries:${industry}`));
      options.setOptions(data.options);

      featureFlags.setReady(true);
      options.setReady(true);
    },
  });
};

const navElements: NavElement[] = [
  {
    name: "Catalogo",
    href: "/catalog",
    isCurrent: ({ path }) => path === "/catalog",
  },
  {
    name: "I tuoi corsi",
    href: "/lms",
    isCurrent: ({ path }) => path.startsWith("/lms"),
    requiredRole: "employee",
  },
  { name: "Piani formativi", href: "/company/plans", requiredRole: "manager" },
  {
    name: "Corsi acquistati",
    href: "/company/storage",
    requiredRole: "manager",
    requiredFeatureFlag: "moxiaDisabled",
  },
  {
    name: "Corsi",
    href: "/company",
    requiredRole: "manager",
    requiredFeatureFlag: "moxiaEnabled",
    children: [
      {
        name: "Acquistati",
        href: "/company/storage",
        isCurrent: ({ path }) => path.startsWith("/company/storage"),
      },
      {
        name: "Caricati dalla tua azienda",
        href: "/company/products",
        isCurrent: ({ path }) => path.startsWith("/company/products"),
      },
    ],
  },
  {
    name: "Gestione",
    href: "/company",
    requiredRole: "manager",
    children: [
      {
        name: "La tua azienda",
        href: "/dashboard",
        isCurrent: ({ path }) => ["/company/fondimpresa", "/dashboard"].includes(path),
      },
      {
        name: "Preventivi e ordini",
        href: "/company/orders",
        isCurrent: ({ path }) => path.startsWith("/company/orders"),
      },
      {
        name: "Gestione persone",
        href: "/company/employees",
        isCurrent: ({ path }) => path.startsWith("/company/employees"),
      },
      {
        name: "Attestati",
        href: "/company/certificates",
        isCurrent: ({ path }) => path.startsWith("/company/certificates"),
      },
    ],
  },
];

type LayoutProps = {
  title?: string;
  description?: string;
  fullwidthPage?: boolean;
  fullwidthNavbar?: boolean;
  withoutSeo?: boolean;
  withoutNav?: boolean;
  withoutFooter?: boolean;
  isPublic?: boolean;
  isLoading?: boolean;
  user?: User;
  admin?: SessionAdmin;
  loader?: ReactNode;
  children: ReactNode;
  isSearching?: boolean;
  scrolling?: boolean;
  onBackdropClick?: () => void;
  wrapperClassname?: string;
};

function UserDropdown({ user, path }: { user?: User; path: string }) {
  const router = useRouter();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const posthog = usePostHog();

  const { data, isLoading } = api.user.get.useQuery(undefined, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: selectActiveCompany } = api.user.selectActiveCompany.useMutation({
    onSuccess: async () => {
      await fetch(learnAppUrl("/api/auth/session?refetch=1"));
      location.href = "/";
    },
    onError: () => {
      showToast("Non è stato possibile cambiare l'azienda selezionata.", "error");
    },
  });

  const { data: industries } = api.userCompany.getAllIndustries.useQuery(undefined, {
    enabled: !gilityEnv.production(),
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: toggleCompanyIndustry } = api.userCompany.toggleCompanyIndustry.useMutation({
    onSuccess: () => {
      router.reload();
    },
  });

  const { options } = useOptions();

  if (!user || isLoading) {
    return null;
  }

  const showIndustryDropdown = !gilityEnv.production() && user.role === "manager" && industries;

  const hasManyCompanies = (data?.employees.length ?? 0) > 1;

  return (
    <NavigationMenu.Root className="relative flex" delayDuration={0}>
      <NavigationMenu.List className="relative flex flex-col lg:flex-row">
        {options.companyCatalogLinkEnabled && (
          <NavigationMenu.Item>
            <NavLink
              item={{
                name: `Corsi di ${user.activeCompanyName}`,
                href: "/company/catalog",
              }}
              isBlue={false}
              current={path.includes("company/catalog")}
            />
          </NavigationMenu.Item>
        )}
        {user.role == "manager" && (
          <NavigationMenu.Item>
            <NavLink
              item={{
                name: "I tuoi corsi",
                href: "/lms",
              }}
              isBlue={false}
              current={path.startsWith("/lms")}
            />
          </NavigationMenu.Item>
        )}
        <NavigationMenu.Item
          className="flex items-center"
          key={"user"}
          data-testid="user-dropdown-trigger">
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="flex gap-2">
              <Button
                color="ghost"
                className={cn(
                  "radix-state-active:text-red group/trigger p-4",
                  "cursor-pointer appearance-none items-center rounded text-left outline-none",
                  "text-blue hover:bg-transparent hover:text-pink hover:opacity-100 focus:outline-none focus:ring-0",
                )}>
                <span className="text-md flex-grow select-none truncate">
                  <UserRound className="h-5 w-5 flex-shrink-0" />
                </span>
                <span className="text-base lg:hidden">Profilo</span>
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end" className="min-w-[180px]">
              {hasManyCompanies ? (
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger
                    direction="left"
                    enabled={hasManyCompanies}
                    data-testid="user-dropdown-companies-trigger">
                    {user.activeCompanyName}
                    {user.role === "manager" && " (Admin)"}
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className="min-w-auto">
                      {data?.employees.map((employee) => {
                        const selectedCompany = employee.company.id === user.activeCompanyId;
                        return (
                          <DropdownMenuItem
                            key={employee.id}
                            className={cn(
                              "p-2 focus:bg-transparent",
                              selectedCompany && "bg-gray-100",
                            )}
                            onClick={() => selectActiveCompany({ employeeId: employee.id })}
                            StartIcon={() => <Building aria-hidden="true" size={16} />}>
                            {employee.company.name}
                            {employee.role === "manager" && " (Admin)"}
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              ) : (
                <DropdownMenuItem
                  variant="readonly"
                  StartIcon={() => <Building aria-hidden="true" size={16} />}>
                  {user.activeCompanyName}
                  {user.role === "manager" && " (Admin)"}
                </DropdownMenuItem>
              )}
              <DropdownMenuSeparator />

              {showIndustryDropdown && (
                <>
                  <DropdownMenuSub>
                    <DropdownMenuSubTrigger direction="left" enabled={!!industries}>
                      Industry
                    </DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent>
                        {industries.map((industry) => (
                          <DropdownMenuItem
                            key={industry.id}
                            className={cn(
                              "p-2 focus:bg-transparent",
                              industry.selected && "bg-gray-100",
                            )}
                            onClick={() => toggleCompanyIndustry({ industryId: industry.id })}>
                            <div className="flex items-center">
                              {industry.selected && <Check className="me-2 h-4 w-4" />}
                              {industry.name}
                            </div>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                  <DropdownMenuSeparator />
                </>
              )}

              <DropdownMenuItem StartIcon={() => <Book aria-hidden="true" size={16} />} href="/lms">
                I tuoi corsi
              </DropdownMenuItem>
              <DropdownMenuItem
                StartIcon={() => <UserRound aria-hidden="true" size={16} />}
                href="/user/profile">
                Profilo
              </DropdownMenuItem>
              <DropdownMenuItem
                StartIcon={() => <LogOut aria-hidden="true" size={16} />}
                onClick={() => {
                  posthog?.reset();
                  void signOut({ callbackUrl: "/auth/login" });
                }}>
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <NavLink
            item={{
              href: "#",
              name: <span className="text-base lg:hidden">Assistenza</span>,
              icon: <Headset className="h-5 w-5" />,
            }}
            isBlue={false}
            current={false}
            onClick={(evt) => {
              evt.preventDefault();
              setDialogOpen(true);
            }}
          />
        </NavigationMenu.Item>
        {user.role === "manager" && (
          <NavigationMenu.Item>
            <CartDropdown />
          </NavigationMenu.Item>
        )}
      </NavigationMenu.List>
      <AssistenzaDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </NavigationMenu.Root>
  );
}

const Navigation = ({
  fullwidthNavbar = false,
  admin,
  user,
  isLoading,
  isPublic,
  scrolling,
}: LayoutProps) => {
  const path = useRouter().asPath;
  const { flags, ready: flagsReady } = useFeatureFlags();
  const { options, ready: optionsReady } = useOptions();

  const publicButtons = (
    <>
      <Button href="/dashboard" color="ghost" size="sm">
        Accedi
      </Button>
      <Button href="/demo" size="sm">
        Chiedi una demo
      </Button>
    </>
  );

  const elements = !isLoading ? (isPublic ? [] : navElements) : undefined;

  return (
    <>
      <Navbar
        scrolling={scrolling}
        fullwidth={fullwidthNavbar}
        elements={user && flagsReady && optionsReady ? elements : []}
        path={path}
        user={user}
        logoHref="/dashboard"
        featureFlags={flags}
        options={options}
        colorVariant="white">
        {isPublic ? publicButtons : <UserDropdown user={user} path={path} />}
      </Navbar>
      {!isPublic && admin && user && <StalkerNavbar admin={admin} user={user} />}
    </>
  );
};

const DefaultLoader = () => <G className="mx-auto mt-12 animate-pulse text-blue/5" />;

const MainCointainer = ({
  fullwidthPage,
  isLoading,
  children,
  scrolling,
  loader: Loader = <DefaultLoader />,
}: LayoutProps) => {
  return (
    <div className={cn("relative flex flex-1 flex-grow flex-col", scrolling && "py-16")}>
      <div className={cn("container mx-auto", fullwidthPage ? "max-w-full" : "max-w-7xl")}>
        {!isLoading ? children : Loader}
      </div>
    </div>
  );
};

const Layout = (props: LayoutProps) => {
  const pageTitle = props.title ? `${props.title} | ${APP_NAME}` : `${APP_NAME} | ${APP_PAYOFF}`;
  const pageDescription = props.description ?? APP_DESCRIPTION;

  const { isSearching, onBackdropClick, wrapperClassname } = props;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Head>

      <div className={cn("relative z-0 flex min-h-screen flex-col", wrapperClassname)}>
        <div
          className={cn(
            "absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-50 duration-300 lg:backdrop-blur-sm",
            isSearching ? "opacity-100" : "opacity-0",
            isSearching ? "pointer-events-auto" : "pointer-events-none",
          )}
          onClick={isSearching ? onBackdropClick : undefined}
        />
        {!props.withoutNav && <Navigation {...props} />}

        <MainCointainer {...props} />

        {!props.withoutFooter && <Footer className="mt-10" />}
      </div>
    </>
  );
};

export default function Shell(props: LayoutProps) {
  const { session } = useRedirectToLoginIfUnauthenticated(props.isPublic);
  useGlobalStores(session);

  return <Layout {...props} user={session?.user} admin={session?.admin} />;
}
