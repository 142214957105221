import type { JSX } from "react";

import { cn } from "@gility/lib";

import { RadioTower, Users2 } from "../../icon";

export type LiveFlagProps = JSX.IntrinsicElements["span"] & {
  small?: boolean;
  onSite?: boolean;
};

const LiveFlag = ({ small = false, onSite = false, className, ...props }: LiveFlagProps) => {
  const FlagIcon = onSite ? Users2 : RadioTower;

  return (
    <span
      className={cn(
        "inline-flex flex-row items-center gap-x-1 rounded-md bg-pink-100 px-2 py-1 font-medium text-pink",
        small ? "my-[2px] text-xs" : "text-sm",
        className,
      )}
      {...props}>
      <FlagIcon className={cn("inline-block", small ? "h-3 w-3" : "h-4 w-4")} />
      {onSite ? "In presenza" : "Live"}
    </span>
  );
};

export default LiveFlag;
