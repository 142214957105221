import { type ReactNode } from "react";
import Link from "next/link";

import { cn } from "@gility/lib";
import { type SVGComponent } from "@gility/lib/types/SVGComponent";

export enum PillStatus {
  Disabled,
  Waiting,
  Ok,
  Warning,
  Error,
  Danger,
}

const mapStatusColor: Record<PillStatus, string> = {
  [PillStatus.Disabled]: "bg-gray-500",
  [PillStatus.Waiting]: "bg-blue-500",
  [PillStatus.Ok]: "bg-green-500",
  [PillStatus.Warning]: "bg-yellow-500",
  [PillStatus.Error]: "bg-red-500",
  [PillStatus.Danger]: "bg-red-600",
};

export type PillProps = Omit<JSX.IntrinsicElements["a"], "ref"> & {
  text: ReactNode;
  href?: string;
  border?: boolean;
  status?: PillStatus;
  startIcon?: SVGComponent;
  endIcon?: SVGComponent;
  truncate?: number;
};

export const Pill = ({
  className,
  href,
  border = false,
  text,
  status,
  startIcon: StartIcon,
  endIcon: EndIcon,
  truncate,
  ...props
}: PillProps) => {
  const Component = href ? Link : "span";

  return (
    <Component
      className={cn(
        "inline-flex space-x-1 rounded-md px-2 py-1 text-xs leading-3 text-black",
        href ? "cursor-pointer hover:bg-ice" : "bg-ice",
        border && "border border-brand/50 bg-white",
        className,
      )}
      href={href ?? ""}
      {...props}>
      {!status || (
        <span
          className={`line my-auto h-[8px] w-[8px] truncate rounded-full ${mapStatusColor[status]}`}></span>
      )}
      {StartIcon && <StartIcon />}
      <span className={cn(truncate ? `truncate w-[${truncate}ch]` : "")}>{text}</span>
      {EndIcon && <EndIcon />}
    </Component>
  );
};
